import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { IntegrationTile } from '@/features/integrations/integration-tile';
import { capitalize } from '@/utils/types';
import { IntegrationsContext } from '@/features/integrations/context';

export interface TrackingIntegrationTileProps {
  name: string;
  children: any;
}

export function TrackingIntegrationTile({
  name,
  children,
}: TrackingIntegrationTileProps) {
  const { integrationsStatus, toggleTrackingIntegration } =
    useContext(IntegrationsContext);

  const isSelected = useMemo(
    () => integrationsStatus?.trackingIntegrations?.[name] === `YES`,
    [integrationsStatus],
  );

  return (
    <IntegrationTile
      key={name}
      mode="toggle"
      allowToDisable
      onChange={() => toggleTrackingIntegration(name)}
      isSelected={isSelected}
      staticImage={<ImageWrapper>{children}</ImageWrapper>}
    >
      Use {capitalize(name)} to analyze user behavior sessions tagged with
      Visually experiences data
    </IntegrationTile>
  );
}

const ImageWrapper = styled.div`
  padding: 2rem 2rem 2rem 1rem;
  img {
    height: 35px;
    object-fit: contain !important;
  }
`;
