import * as React from 'react';
import styled from 'styled-components';
import { useRef } from 'react';
import { PageLayout } from '@/components/page-layout';
import { ReviewsIntegrations } from '@/features/integrations/reviews-integrations';
import { FacebookIntegration } from './facebook';
import { GoogleAnalyticsIntegration } from '@/features/integrations/google-analytics';
import { KlavioIntegration } from '@/features/integrations/klavio';
import {
  IntegrationsContext,
  newIntegrationsContext,
} from '@/features/integrations/context';
import { InstagramIntegration } from '@/features/integrations/instagram';
import { TrackingIntegrationTile } from '@/features/integrations/tracking/tracking-integration';

// @ts-ignore
import amplitude from './tracking/amplitude.svg';

// @ts-ignore
import clarity from './tracking/clarity.png';

// @ts-ignore
import contentsquare from './tracking/contentsquare.svg';

// @ts-ignore
import fullstory from './tracking/fullstory.svg';

// @ts-ignore
import heatmap from './tracking/heatmap.png';

// @ts-ignore
import hotjar from './tracking/hotjar.svg';

// @ts-ignore
import segment from './tracking/segment.svg';

export function IntegrationsPage() {
  const wrapperRef = useRef(null);
  const integrationsContext = newIntegrationsContext(true);
  return (
    <PageLayout
      hideBackButton={false}
      heading="Integrations"
      description="Connect Visually.io to 3rd party platforms"
    >
      <IntegrationsContext.Provider value={integrationsContext}>
        <Grid ref={wrapperRef}>
          <KlavioIntegration />
          <FacebookIntegration />
          <GoogleAnalyticsIntegration />
          <InstagramIntegration />
          <TrackingIntegrationTile name="amplitude">
            <img src={amplitude} alt="amplitude" />
          </TrackingIntegrationTile>
          <TrackingIntegrationTile name="clarity">
            <img src={clarity} alt="clarity" />
          </TrackingIntegrationTile>
          <TrackingIntegrationTile name="contentsquare">
            <img src={contentsquare} alt="contentsquare" />
          </TrackingIntegrationTile>
          <TrackingIntegrationTile name="fullstory">
            <img
              src={fullstory}
              alt="fullstory"
              style={{ height: `25px`, margin: `0.5rem 0` }}
            />
          </TrackingIntegrationTile>
          <TrackingIntegrationTile name="heatmap">
            <img src={heatmap} alt="heatmap" />
          </TrackingIntegrationTile>
          <TrackingIntegrationTile name="hotjar">
            <img src={hotjar} alt="hotjar" />
          </TrackingIntegrationTile>
          <TrackingIntegrationTile name="segment">
            <img src={segment} alt="segment" />
          </TrackingIntegrationTile>
          <ReviewsIntegrations mode="toggle" wrapperRef={wrapperRef} />
        </Grid>
      </IntegrationsContext.Provider>
    </PageLayout>
  );
}

const Grid = styled.div`
  display: grid;
  width: 90rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5rem;
`;
