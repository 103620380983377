import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IntegrationTile } from '@/features/integrations/integration-tile';
import { IntegrationsContext } from '@/features/integrations/context';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import {
  onOpenInstagramOAuthPopup,
  useStoreSettingsApi,
} from '@/features/integrations/instagram/api';

export const InstagramIntegration = () => {
  const c = useContext(AccountContext);
  const { integrationsStatus, toggleInstagram } =
    useContext(IntegrationsContext);

  const isInstagramEnabled = useFeatureBit(FeatureBit.INSTAGRAM_INTEGRATION);

  const { encrypt } = useStoreSettingsApi();

  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(integrationsStatus.instagram === `YES`);
  }, [integrationsStatus.instagram]);

  useEffect(() => {
    const bc = new BroadcastChannel(`insta_auth`);
    bc.onmessage = () => {
      setSelected(true);
    };
    return () => bc.close();
  }, []);

  const handleChange = async () => {
    if (selected) {
      await toggleInstagram();
    } else {
      await onOpenInstagramOAuthPopup(encrypt, c?.account?.store?.alias)();
    }
  };

  if (!isInstagramEnabled) {
    return null;
  }
  return (
    <IntegrationTile
      key="insta"
      mode="toggle"
      allowToDisable
      onChange={handleChange}
      isSelected={selected}
      staticImage={
        <Container>
          <StaticImage
            height={40}
            placeholder="none"
            loading="eager"
            src="instagram.png"
            alt="instagram"
          />
          <Title>Instagram</Title>
        </Container>
      }
    >
      Connect to Instagram to display components based on <br /> social content
    </IntegrationTile>
  );
};
const Container = styled.div`
  margin: 1.45rem 0 2rem 2rem;
  display: flex;
  justify-content: start;
  align-items: center;
`;
const Title = styled.span`
  font-family: Inter, serif;
  margin-left: 2rem;
  color: #505056;
  font-weight: 500;
  font-size: 2rem;
`;
